/*************
WEB FONTS
**************/

@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700');

/**************
    VARIABLES
***************/
:root {
    --title-blue: #68A7D7;
    --title-orange: #f7914d;
    --title-red: #cb140a;
    --text-blue: #105D98;
    --text-gray: #535557;
    --btn-blue: #1D75BC;
    --btn-gray: #94999F;
    --badge-blue: #53a8dc;
    --orange: #E5B739;
    --border-green: #E5B739;
    --border-orange: #f7914d;
    --border-red: #cb140a;
    --border-golden: #FFD700;
    --badge-zelle: #671CCA;
    --btn-darkgreen: #00602B;
    --btn-lightgreen: #4E9F3D;
    --btn-hoverlightgreen: #6AB250;
}

/**********
HTML TAGS    
**********/
html {
    height:100%;
}

body {
    font-family: Poppins, Roboto, sans-serif;
    color: var(--text-gray);
   
}

a {
    text-decoration: none;
}

    a:link {
        text-decoration: none;
    }

    a:visited {
        text-decoration: none;
    }

input {
    text-align: center;
}

.componentload {
    animation: fade-in 1s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}


.loader-fullcontainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0.6;
}
.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    pointer-events: none;
}

.loaderfront {
    z-index:15;
}

.border-teal {
    border-color: var(--text-blue);
}

.border-black {
    border-color: black;
}

.border-orange {
    border-color: var(--border-orange);
}

.badge-zelle {
    background-color: var(--badge-zelle);
    color: white;
    padding: 5px;
    border-radius: 8px;
}

.center-block {
    justify-content: center;
    align-content: center;
    text-align: center;
}

.input-group-text-transparent {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    font-size: 1.75rem;
    font-weight: bolder;
    margin-top: 18px;
}

.crypto-address {
    width: 100%;
    text-wrap: wrap;
    color: var(--btn-gray);
    font-weight: bolder;
    margin-right: 5px;
    word-wrap: break-word;
}

.input-group-currency {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.input-group-fa {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.input-group-currency-cards {
    background-color: var( --bs-border-color);
}

.selectcurrency {
    background-color: var( --bs-border-color);
    border-color: var( --bs-border-color);
}

.limit-text-center {
    justify-content: center;
    font-size: 0.8rem;
    text-align: center;
    color: var(--text-blue);
}

.p2pControlNumber {
    background-color: #00602B;
    color: white;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}




/**********LOGIN***********/

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: white;
    background: -webkit-linear-gradient(to left,#fff,#e3e3e3);
    background: -o-linear-gradient(to left,#fff,#e3e3e3);
    background: -moz-linear-gradient(to left,#fff,#e3e3e3);
    background: linear-gradient(to left,#46a278,#59e85f);
}

.form-group {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

    .form-group input {
        display: block;
        margin-bottom: 10px;
        font-size: 1.3em;
        padding: 10px;
        color: #fff;
        background-color: transparent;
        border: 0;
        border-bottom: 2px solid rgba(255, 255, 255, .2);
        border-radius: 4px;
        font-weight: 300;
        outline: none;
        font-weight: 100;
        letter-spacing: 4px;
        margin-left: 10px;
        max-width: 350px;
    }

    .form-group input:hover, form input:focus {
        border-bottom: 2px solid rgba(255, 255, 255, .9);
        border-radius: 5%;
    }

    .form-group input::placeholder {
        color: white;
        font-weight: 100;
        letter-spacing: 4px;
        margin-left: 10px;
    }

input .input-field {
    border: 1px solid black;
}


input[type="submit"] {
    margin: 40px auto;
    border: 1px solid white;
    display: block;
    width: 100px;
}

    input[type="submit"]:hover {
        border: 1px solid white;
        display: block;
        width: 100px;
    }

    .form-group p {
        margin-top: 10px;
        text-align: right;
    }

        .form-group p a {
            color: rgb(0, 0, 0);
        }


/**************
    PAYOUTS
**************/
.payout-title-color {
    color: var(--title-orange) !important;
}

.box-payout {
    /* float: left; */
    text-align: left;
    background-color: white;
    border: 1px solid var(--border-orange);
    color: black;
    padding: 5px;
    display: inline-block;
    white-space: nowrap;
    /* text-align: right; */
    box-shadow: rgba(0,0,0,0.2)5px 5px 4px 5px;
    border-radius: 4px;
    width: 100%;
}

    .box-payout .icon-single img {
        margin-left: 15px;
        height: 50px;
        max-width: 110px;
    }

    .box-payout .iconlist img {
        margin-left: -15px;
        width: 40px;
        margin-top: 5px;
    }

.header-payout {
    height:130px;
}
/********PAYOUT ALERT************/
.alert {
    border-radius: 3px;
    -webkit-border-radius: 0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.61);
}

.alert-white {
    background-image: linear-gradient(to bottom,#FFFFFF,#F9F9F9);
    color: #404040;
    position: relative;
}

    .alert-white .icon {
        text-align: center;
        width: 45px;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        margin-top: 11px;
        margin-left: 10px;
        color: orange
    }


/****************************
EXPRESS & REGULAR METHODS
*** ************************/
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.creditcard-font {
    font-size:small;
    margin-top:5px;
}

.title {
    margin-top:15px;
}

.text-title-color {
    color: var(--title-blue);
}

.colgap-20 {
    margin-right:50px;
    margin-left:30px;
}


.box-deposit {
    /* float: left; */
    text-align: left;
    background-color: white;
    border: 1px solid var(--border-green);
    color: black;
    padding: 5px;
    display: inline-block;
    white-space: nowrap;
    /* text-align: right; */
    box-shadow: rgba(0,0,0,0.2)5px 5px 4px 5px;
    border-radius: 4px;
    width: 100%;
}

.othermethod-color {
    background-color: #bbf2d6 !important; /*#a7cce6 !important;*/
    min-height: 62px;
    cursor: pointer;
}

.express-color {
    background-color: #a7cce6 !important; /*#bbf2d6 !important;*/ /*lightsteelblue !important;*/
    min-height: 62px;
    cursor: pointer;
}

.express-color-disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #e3e3e3;
    min-height: 62px;
}

.payoutmethod-color {
    background-color: #EA4C4C !important;
    min-height: 62px;
    cursor: pointer;
}

.payout-title {
    font-size:1.5rem;
    font-weight:bold;
}

.height-68 {
    min-height:68px !important;
}

.pointer {
    cursor: pointer;
}

.othermethod-text {
    text-wrap: wrap;
    position: relative;
    top: 14px;
    
}

.box-golden {
    border: 1px solid var(--border-golden);
}

.icon-single {
    position: relative;
    text-align: center;
}

.icon-single-left {
    position: relative;
    text-align: left;
}

.box-deposit .icon-single img {
    margin-left: 15px;
    height: 50px;
    max-width: 95px;
}



.iconlist {
    position: relative;
    margin-left: 20px;
    font-size: x-large;
}

.box-deposit .iconlist img {
    margin-left: -15px;
    width: 40px;
    margin-top: 5px;
}



.limit-text {
    float: right;
    flex-flow: row;
    font-size: 0.8rem;
    text-align: center;
    padding-top: 6px;
    margin: auto;
}

.checkSelect {
    margin-left:15px;
    margin-top:10px;
}


.mr-2 {
    margin-right: .5rem !important;
}

.card-header {
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    background-color: #fff;
}

.card .table {
    margin-bottom: 0;
}

    .card .table td,
    .card .table th {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

.badge-dot {
    font-size: .875rem;
    font-weight: 400;
    padding-right: 0;
    padding-left: 0;
    text-transform: none;
    background: transparent;
    color: black;
}

    .badge-dot i {
        display: inline-block;
        width: .375rem;
        height: .375rem;
        margin-right: .375rem;
        vertical-align: middle;
        border-radius: 50%;
    }

.accordion-button {
    background-color: var(--badge-blue) !important;
}

.media-body {
    flex: 1 1;
}

.media {
    display: flex;
}

/**************
 BUTTON STYLE
****************/

.btn-back-order {
    float: left;
}

.btn-gray {
    background-color: var(--btn-gray);
    color: white;
    min-width: 200px;
}


.btn-white {
    background-color: white;
    color: black;
}

.btn-deposit-order {
    float: right;
}

.btn-blue {
    background-color: var(--btn-blue);
    color: white;
    min-width: 200px;
}

.btn-darkgreen {
    background-color: var(--btn-darkgreen);
    color: white;
    min-width: 200px;
}

.btn-deposit {
    background-color: var(--btn-lightgreen);
    color: white;
    min-width: 220px;
    min-height:55px;
}

    .btn-deposit:hover {
        background-color: var(--btn-hoverlightgreen);
        box-shadow: rgba(250,250,250,0.8) 2px 2px 1px 2px;
    }

.btn-deposit-express {
    background-color: var(--btn-lightgreen);
    color: white;
    min-width: 200px;
    margin-left: 15px !important;
    border-radius: var(--bs-border-radius) !important;
}
    .btn-deposit-express:hover {
        background-color: var(--btn-hoverlightgreen);
        box-shadow: rgba(250,250,250,0.8) 2px 2px 1px 2px;
    }

.btn-squared-default {
    width: 100px !important;
    height: 100px !important;
    font-size: 10px;
}

    .btn-squared-default:hover {
        border: 3px solid white;
        font-weight: 800;
    }

.btn-squared-default-plain {
    width: 100px !important;
    height: 100px !important;
    font-size: 10px;
}

    .btn-squared-default-plain:hover {
        border: 0px solid white;
        background-color: var(--border-orange);
    }


/************
    TABLES
 **************/
    .table .thead-light th {
    color: #8898aa;
    border-color: #e9ecef;
    background-color: #f6f9fc;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef;
    border-top: 2px solid #e9ecef;
}

.table.align-items-center td,
.table.align-items-center th {
    vertical-align: middle;
}
.align-items-center {
    align-items: center !important;
}

.table td .progress {
    width: 120px;
    height: 3px;
    margin: 0;
}

/**********Priority Methods**************/
.prioritymethods {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    /*border: 1px solid var(--text-gray);*/
    border-radius: 4px;
    box-shadow: rgba(0,0,0,0.2)2px 2px 1px 2px;
}

/******************
 CHECKBOX ANIMATE
******************/
#bonuses {
    transition: all 1s;
}
/*.bonusbox{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }*/
.headergradient {
    background: linear-gradient(135deg, #E3E3E3 0%,#5D6874 100%);
}
/*.headergradient2{
            background:linear-gradient(135deg, #23aa8a 0%,#b8efc5 100%);
        }*/
.bonusmaterial {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    min-height:130px;
}

.bonusborder {
    border-radius: 0.375rem;
}

.headerborder {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.border-teal {
    border-color: teal;
    border-width: 1px;
    border-style: solid;
}

.ribbon-wrapper-green {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;
}

.ribbon-green {
    font-size: 14px;
    font-weight: bold;
    color: #111;
    text-align: center;
    text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 3px 0;
    left: -5px;
    top: 15px;
    width: 120px;
    background: linear-gradient(135deg, #23aa8a 0%,#b8efc5 100%);
    color: black;
    -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}


.checkbox-animate {
    font-family: arial;
    font-size: 16px;
}

    .checkbox-animate label {
        position: relative;
        cursor: pointer;
    }

        .checkbox-animate label input {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
        }

    .checkbox-animate .input-check {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 2px solid #ccc;
        position: relative;
        top: 6px;
        margin-right: 7px;
        transition: 0.4s;
    }

        .checkbox-animate .input-check::before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 6px;
            border-bottom: 4px solid #fff;
            border-left: 4px solid #fff;
            transform: scale(0) rotate(-45deg);
            position: absolute;
            top: 6px;
            left: 4px;
            transition: 0.4s;
        }

.checkbox-animate label input:checked ~ .input-check {
    background-color: #06b1c5;
    border-color: #06b1c5;
    animation-name: input-animate;
    animation-duration: 0.7s;
}

    .checkbox-animate label input:checked ~ .input-check::before {
        transform: scale(1) rotate(-45deg);
        animation-name: input-check;
        animation-duration: 0.2s;
        animation-delay: 0.3s;
    }

@keyframes input-animate {
    0% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.3,0.7);
    }

    55% {
        transform: scale(1);
    }

    70% {
        transform: scale(1.2,0.8);
    }

    80% {
        transform: scale(1);
    }

    90% {
        transform: scale(1.1,0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes input-check {
    0% {
        transform: scale(0) rotate(-45deg);
    }

    100% {
        transform: scale(1) rotate(-45deg);
    }
}

@media only screen and (max-width: 568px) {
    .box-deposit {
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    img {
        width: 150%;
    }
}

@media only screen and (min-width: 300px) and (max-width: 967px) {
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xs-4 {
        flex: 0 0 auto;
        width: 33%;
    }

    .box-deposit .icon-single img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        height: 60px;
    }

    .box-payout .icon-single img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        height: 60px;
    }

    .phone-text {
        text-wrap: wrap;
        position: relative;
        top: 17px;
    }

    .creditcard-font {
        font-size: 15px;
    }

    .othermethod-color {
        min-height: 98px;
    }

    .payoutmethod-color {
        min-height: 98px;
    }

    .express-color {
        min-height: 98px;
    }

    .btn-deposit-order {
        float: left;
        width:100%;
       
    }

    .btn-back-order {
        float: right;
        width: 100%;
       
    }

    .actionbuttons {
        display: flex;
        flex-direction: column-reverse;
    }

    .table-font {
        font-size:0.7rem;
    }
    .badge-dot {
        font-size: 0.7rem;
    }

}
